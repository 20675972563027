import { AxiosResponse } from 'axios';

import { privateApi } from 'api/api';
import { STATE_TICKET_ANY } from 'constants/support';
import { IPagination, ISort } from 'types/table';
import { INewTicket, Ticket, TicketFilters, TicketResponse } from 'types/ticket';
import { extractDateFromIso } from 'utils/utils';

const TicketApi = {
  getTickets: (
    filters: TicketFilters,
    pagination: IPagination,
    sort: ISort,
  ): Promise<AxiosResponse<TicketResponse>> => {
    const limit = pagination.rowsPerPage;
    const offset = pagination.page * limit;
    const newFilters = {
      ...filters,
      from_date: (filters.from_date && extractDateFromIso(filters.from_date)) || undefined,
      to_date: (filters.to_date && extractDateFromIso(filters.to_date)) || undefined,
      stage: filters.stage === STATE_TICKET_ANY ? undefined : filters.stage,
    };
    newFilters.period = undefined;
    return privateApi.get(`/ticket`, { params: { ...newFilters, limit, offset, ...sort } });
  },

  getTicketDetails: (id: string): Promise<AxiosResponse<Ticket>> => privateApi.get(`/ticket/${id}/`),

  setTicketAsClosed: (id: string): Promise<AxiosResponse<Ticket>> => privateApi.put(`/ticket/${id}/close`),

  createTicket: (ticketData: INewTicket): Promise<AxiosResponse<Ticket>> => privateApi.post('/ticket/', ticketData),

  fetchUploadUrl: (filename: string, file_size: number): Promise<AxiosResponse<any>> =>
    privateApi.get('/ticket/attach', { params: { filename, file_size } }),

  uploadAttachment: (id: number, formData: FormData): Promise<AxiosResponse> =>
    privateApi.post(`/ticket/${id}/attachment`, formData),
};

export default TicketApi;
